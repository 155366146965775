import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { saveProfile } from '../../../redux/reducers/profileSlice';
import CropperCom from './CropperCom';
import inputErrors from '../../helpers/validation'
import Progress from './Progress';
import Loader from '../../helpers/Loader';
import { ReactComponent as AndIcon } from '../../../images/and.svg';
const BrideGroomForm = ({ onBack, onNext }) => {
  const formRef = useRef(null);
  const dispatch = useDispatch()
  const { weddingData: profileData, loading } = useSelector((state) => state.profile)
  const [values, setValues] = useState({
    groomFullName: "",
    brideFullName: "",
    groomParentDetail: "",
    brideParentDetail: "",
    BlessingMessage: "",
    groomImage: {
      url: "",
      public_id: ""
    },
    brideImage: {
      url: "",
      public_id: ""
    },
    displayOrder:""
  })
  const [isSubmitting, setSubmitting] = useState(false);
  const [showCropper, setShowCropper] = useState(false)
  const [errors, setErrors] = useState({})
  const [imageType, setImageType] = useState("")
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const validationErrors = inputErrors(values)
    setErrors(validationErrors)
    setSubmitting(true)
  }

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        savedata()
      } else {
        formRef.current.scrollIntoView({ behavior: "smooth" });
      }

    }
  }, [isSubmitting, errors])

  const savedata = () => {
    setSubmitting(false)
    dispatch(saveProfile({ values, onNext }))
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setValues({ ...profileData })
  }, [profileData])


  const handleCropper = () => setShowCropper(prevValue => !prevValue)

  const opencropper = (imageType) => {
    setImageType(imageType)
    handleCropper()
  }
  if (loading) {
    return <Loader />
  }
  return (
    <>
      <Progress activeStep={1} />
      <div className="form-screen">
        <div className="container mb-3">
          <div className="row d-flex row-containers  mx-2">
            <div className="col-lg-6 col-md-11 form-container">
              <h3 className='form-heading'> Groom and Bride Details</h3>
              <div className="form-group">
                <label>Display Order:</label>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="displayOrder"
                      value="groomFirst"
                      checked={values.displayOrder === "groomFirst"}
                      onChange={handleChange}
                    />
                    Groom First
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="displayOrder"
                      value="brideFirst"
                      checked={values.displayOrder === "brideFirst"}
                      onChange={handleChange}
                    />
                    Bride First
                  </label>
                </div>
              </div>

              <form className='form-div' onSubmit={handleSubmit} ref={formRef} >
                <label className={errors.BlessingMessage ? 'blessing-label blessing-label-error' : "blessing-label"} >Grandparents Names & Blessings</label>
                <textarea
                  name="BlessingMessage"
                  value={values.BlessingMessage}
                  onChange={handleChange}
                  className={errors.BlessingMessage ? "blessing-input-feild blessing-input-error" : "blessing-input-feild"}
                  placeholder='Eg: With blessings of our guiding angels, Grandfather’s Name & Grandmother’s Name, we cordially invite you.'
                />
                <div className='error-wedding-div'>  {errors.BlessingMessage && (<small className="error-msg-wedding">{errors.BlessingMessage}</small>)}</div>
                <div className={errors.groomFullName ? "form-input-div-error" : "form-input-div"}>
                  <input type="search"
                    name="groomFullName"
                    value={values.groomFullName}
                    onChange={handleChange}
                    placeholder=' '
                    className={errors.groomFullName ? "form-input-field-error" : "form-input-field"}
                  />
                  <label>Groom Full Name <span style={{ color: "red" }}> *</span></label>
                </div>
                <div className='error-wedding-div'>  {errors.groomFullName && (<small className="error-msg-wedding">{errors.groomFullName}</small>)}</div>
                <span className='extra-label-detail'>Groom Parents Name</span>
                <div className={errors.groomParentDetail ? "form-input-div-error" : "form-input-div"}>

                  <input type="search"
                    name="groomParentDetail"
                    value={values.groomParentDetail}
                    onChange={handleChange}
                    placeholder=' '
                    className={errors.groomParentDetail ? "form-input-field-error" : "form-input-field"}
                  />

                  <label>[Eg (Father Name & Mother Name)]</label>
                </div>
                <div className='error-wedding-div'>  {errors.groomParentDetail && (<small className="error-msg-wedding">{errors.groomParentDetail}</small>)}</div>
                <div className={errors.brideFullName ? "form-input-div-error" : "form-input-div"}>
                  <input type="search"
                    name="brideFullName"
                    value={values.brideFullName}
                    onChange={handleChange}
                    placeholder=' '
                    className={errors.brideFullName ? "form-input-field-error" : "form-input-field"}
                  />
                  <label>Bride Full Name<span style={{ color: "red" }}> *</span></label>
                </div>
                <div className='error-wedding-div'>  {errors.brideFullName && (<small className="error-msg-wedding">{errors.brideFullName}</small>)}</div>

                <div className={errors.brideParentDetail ? "form-input-div-error" : "form-input-div"}>
                  <span className='extra-label-detail'>Bride Parents Name</span>
                  <input type="search"
                    name="brideParentDetail"
                    value={values.brideParentDetail}
                    onChange={handleChange}
                    placeholder=' '
                    className={errors.brideParentDetail ? "form-input-field-error" : "form-input-field"}
                  />

                  <label>[Eg (Father Name & Mother Name)]</label>
                </div>
                <div className='error-wedding-div'>  {errors.brideParentDetail && (<small className="error-msg-wedding">{errors.brideParentDetail}</small>)}</div>
                <div className='file-input'>
                  <label htmlFor="">Bride and Groom Photo</label>
                  <div className='banner-buttons'>
                    <button type="button" className='default-banner-button' onClick={() => opencropper("groomImage")}  >Upload Groom Photo</button>
                    <span>&</span>
                    <button type="button" className='default-banner-button' onClick={() => opencropper("brideImage")} >Upload Bride Photo</button>

                  </div>
                </div>
                <div className='form-double-buttons'>
                  <button type='button' onClick={onBack}>Back</button>
                  <button type='submit'>Submit</button>
                </div>
              </form>
            </div>
            {/* preview container */}

            <div className="col-lg-5 col-md-12 preview-container-bg p-0 ">
              <h3 className='text-center my-3'>THE GROOM AND BRIDE</h3>
              <div className="bride-groom-container">
              {values.displayOrder === "groomFirst" ? (
                <>
                <div className="bride-groom-div">
                  <img
                    src={values.groomImage ? values.groomImage.url : ""}
                    alt="groomimage"
                    srcSet={`
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 1080w
  `}
                    sizes="(max-width: 320px) 320px, 
                                       (max-width: 640px) 640px, 
                                    (max-width: 1080px) 1080px"
                  />
                  <h3>{values.groomFullName}</h3>
                  <h4>(S/O {values.groomParentDetail})</h4>
                </div>
                {/* <FavoriteIcon className='heart-icon'/> */}
                <div className="and-icon" >
                  <AndIcon />
                </div>
                <div className="bride-groom-div">
                  <img
                    src={values.brideImage ? values.brideImage.url : ""}
                    alt="brideimage"
                    srcSet={`
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 1080w
  `}
                    sizes="(max-width: 320px) 320px, 
                                       (max-width: 640px) 640px, 
                                    (max-width: 1080px) 1080px"
                  />
                  <h3>{values.brideFullName}</h3>
                  <h4>(D/O {values.brideParentDetail})</h4>
                </div>
                </>
              ) : (
                <>
                <div className="bride-groom-div">
                  <img
                    src={values.brideImage ? values.brideImage.url : ""}
                    alt="brideimage"
                    srcSet={`
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 1080w
  `}
                    sizes="(max-width: 320px) 320px, 
                                       (max-width: 640px) 640px, 
                                    (max-width: 1080px) 1080px"
                  />
                  <h3>{values.brideFullName}</h3>
                  <h4>(D/O {values.brideParentDetail})</h4>
                </div>
                <div className="and-icon" >
                  <AndIcon />
                </div>
                <div className="bride-groom-div">
                  <img
                    src={values.groomImage ? values.groomImage.url : ""}
                    alt="groomimage"
                    srcSet={`
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 1080w
  `}
                    sizes="(max-width: 320px) 320px, 
                                       (max-width: 640px) 640px, 
                                    (max-width: 1080px) 1080px"
                  />
                  <h3>{values.groomFullName}</h3>
                  <h4>(S/O {values.groomParentDetail})</h4>
                </div>
              </>
            )}
              </div>
            </div>

          </div>
        </div>
      </div>
      {showCropper && <CropperCom handleCropper={handleCropper} setValues={setValues} values={values} imageType={imageType} />}
    </>
  )
}

export default BrideGroomForm