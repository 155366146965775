import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import './footerslider.css'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';

const FooterSlider = ({ images }) => {
  return (
    <div className='footer-slider' >
      <Swiper
        modules={[Autoplay, EffectFade, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        effect="fade"
        // fadeEffect={{ crossFade: true }}
        // pagination={{ clickable: true }}
        style={{
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        {images.map((image) => (
          <SwiperSlide key={image._id}>
            <img
              src={image.url}
              alt="Special moment"
              className='footer-slider-image'
              srcSet={`
                ${image.url.replace('/upload/', '/upload/w_320/')} 320w,
                ${image.url.replace('/upload/', '/upload/w_640/')} 640w,
                ${image.url.replace('/upload/', '/upload/w_1080/')} 1080w,
                ${image.url.replace('/upload/', '/upload/w_1920/')} 1920w
              `}
              sizes="(max-width: 320px) 320px, 
                     (max-width: 640px) 640px, 
                     (max-width: 1080px) 1080px, 
                     1920px"

            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FooterSlider;
