import React,{useState} from 'react'
import './envelope.css'
import { ReactComponent as GanpatiIcon } from '../../images/ganpati2.svg';
import { ReactComponent as OmIcon } from '../../images/om.svg';
import { ReactComponent as EkOnkarIcon } from '../../images/ekonkar.svg';
import { ReactComponent as KhandaIcon } from '../../images/khanda.svg';
import { ReactComponent as CrossIcon } from '../../images/cross.svg';
import { ReactComponent as BismillahIcon } from '../../images/bismillah.svg';
import { ReactComponent as WaipIcon } from '../../images/waip.svg';
import { ReactComponent as RingsIcon } from '../../images/rings.svg';
import { ReactComponent as KnotIcon } from '../../images/knot.svg';
const Envelope = ({SetEnvelopeScreen,theme,coverIcon,coverText}) => {
  
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleSealClick = () => {
    setIsAnimating(true);
    // Duration should match the total animation time in CSS (e.g., 2s)
    setTimeout(() => {
      setIsVisible(false);
      SetEnvelopeScreen(false)
    }, 800); // 2 seconds
  };
  const iconMapping = {
    EkOnkarIcon: <EkOnkarIcon className="idol-img" />,
    GanpatiIcon: <GanpatiIcon className="idol-img" />,
    OmIcon:<OmIcon className="idol-img" />,
    KhandaIcon:<KhandaIcon className="idol-img" />,
    CrossIcon:<CrossIcon className="idol-img" />,
    BismillahIcon:<BismillahIcon className="idol-img" />,
    WaipIcon:<WaipIcon className="idol-img" />,
    RingsIcon:<RingsIcon className="idol-img" />,
    KnotIcon:<KnotIcon className="idol-img" />,
    None: null, // For "None" option
};
  const SelectedIcon = iconMapping[coverIcon] || null;
  if (!isVisible) return null;
  return (
    <>
  <div  className={`${theme}  envelope ${isAnimating ? 'animate' : ''} ${!isVisible ? 'hidden' : ''} `}>
    <div className={`top ${(!SelectedIcon || !coverText) ? 'justify-content-center' : ''} `}>
    {SelectedIcon && <div className="top-image">
     {SelectedIcon}
    </div>}
    <div className={`top-content ${!SelectedIcon ? 'special-class' : ''}`}>
      <pre>{coverText && coverText}</pre>
    </div>
    </div>
    <div class="bottom">
      <span > <i>An Invite Crafted by <a href="https://aamantraninvites.com/" target='_blank' className='cover-a' >Aamantran</a></i></span>
    </div>
    <div class="wax-seal" onClick={handleSealClick}>
    </div>
</div>
    </>
  )
}

export default Envelope