import React from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { TabTitle } from './GeneralFunctions'

const Display = () => {
  const params=useParams()
  const navigate=useNavigate()
  const screen=params.screen
  TabTitle(`${screen}`)
  return (
    <div className="error-container">
        <div className="error-box">
        {screen === 'warning' ? (
          <>
            <img src="https://res.cloudinary.com/dtg40vnf7/image/upload/v1718884865/aamantran%20assets/warning_qsgbur.png" alt="warning" />
            <h3>Try Again session Expired</h3>
            <button onClick={() => navigate('/')}>Back to Home Page</button>
          </>
        ) : null}

        {screen === 'success' ? (
          <>
            <img src="https://res.cloudinary.com/dtg40vnf7/image/upload/v1718884862/aamantran%20assets/success_tgmpeg.png" alt="success" />
            <h3>Email was successfully updated</h3>
            <button onClick={() => navigate('/')}>Back to Home Page</button>
          </>
        ) : null}

        {screen === 'notfound' ? (
          <>
            <img src="https://res.cloudinary.com/dtg40vnf7/image/upload/v1718884861/aamantran%20assets/notfound_i3s44t.png" alt="notfound" />
            <h3>Page does not exist</h3>
          </>
        ) : null}
        </div>
    </div>
  )
}

export default Display