import React from 'react'
import './navbar.css'
import {NavLink, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { logout } from '../../redux/reducers/authSlice'
import {snackbarContext} from './Snakbar'
const Navbar = () => {
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const {user}=useSelector((state)=>state.auth)
  const dispatch=useDispatch()
  const navigate=useNavigate()

  const logoutFunc=()=>{
    dispatch(logout({navigate,setStateSnackbarContext}))

  }
  return (
    <div className="container-fluid nav_bg">
       <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
  <NavLink className="navbar-brand" to="/"><img src={process.env.PUBLIC_URL + '/finallogo.png'} alt="" /></NavLink>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
      <ul className="navbar-nav ms-auto">
        <li className="nav-item">
          <NavLink  className={({ isActive }) => isActive ? "menu_active nav-link" : "nav-link"}  to="/">HOME</NavLink>
        </li>
        
        <li className="nav-item">
         {user &&  user.hasEnded ? ( <NavLink   className={({ isActive }) => isActive ? "menu_active nav-link" : "nav-link"} to="/links">Edit Page</NavLink>) :( <NavLink   className={({ isActive }) => isActive ? "menu_active nav-link" : "nav-link"} to="/events">My Events</NavLink>)}  
        </li>
        <li className="nav-item">
          <NavLink  className={({ isActive }) => isActive ? "menu_active nav-link" : "nav-link"}  to="/settings">SETTINGS</NavLink>
        </li>
        <li className="nav-item">
        <NavLink    target="_blank"  className="nav-link" to={`/${user&&user.userCode}`} >PREVIEW</NavLink>
        </li>
        <li className="nav-item">
        <button  onClick={()=>logoutFunc()} className='logout-button'>Logout</button>
        </li>
        
      </ul>
    </div>
  </div>
</nav>      
        </div>
  )
}

export default Navbar