import React from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import Background from './Background'
const BackgroundContainer = ({handleDefaultBg,rerenderBg,values}) => {
   return (
    <div className='default-bg-conatiner'>
      <CancelIcon  className='cancel-icon' onClick={handleDefaultBg} />
      <div className="default-bg-screen">
          <div className="select-background-container">
          <h3>Select a Background</h3>
          <div className="background-container">
          <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1718954843/aamantran%20assets/banner1_pqey9r.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1726390164/aamantran%20assets/banner2_amwcwd.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1718954843/aamantran%20assets/banner3_migtj6.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1718954844/aamantran%20assets/banner4_yhukdm.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1718954845/aamantran%20assets/banner5_yzwswm.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1726390164/aamantran%20assets/banner6_oungsg.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1718954847/aamantran%20assets/banner7_z436ty.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
            <Background activeBg={values.bannerImage.url} bg="https://res.cloudinary.com/depixc8dq/image/upload/v1718954848/aamantran%20assets/banner8_oomrwm.jpg" rerenderBg={rerenderBg} handleDefaultBg={handleDefaultBg}  />
          </div>
          </div>
         
      </div>
    </div>
  )
}

export default BackgroundContainer