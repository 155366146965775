import React from 'react'
import './footer.css'
const Footer = ({userCode}) => {
    const countryCode = "91"; // Country code without the plus sign
    const whatsappNumber = "9321060218";
    const defaultMessage = userCode 
      ? `Hello, I am user ${userCode} and need help regarding` 
      : "Hello, I need help regarding";
  
    const encodedMessage = encodeURIComponent(defaultMessage);
    // const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;
  

const whatsappLink = `https://wa.me/${countryCode}${whatsappNumber}?text=${encodedMessage}`;
  return (
    <>
    {/* Part 1: Help Section */}
    <div className="help-section">
      <p>
       <b> Facing issues?{' '}</b>
       <a href={whatsappLink} target='_blank' rel='noreferrer'  className="help-link" >
          Need Help
        </a>
      </p>
    </div>

    {/* Part 2: Main Footer */}
    <footer className="footer">
      <div className="footer-content">
        
        {/* Contact Information */}
        <div className="contact-info">
          <a href="mailto:aamantran0808@gmail.com" className="contact-link">
            <i className="fas fa-envelope"></i> Email Us: aamantran0808@gmail.com
          </a>
          <a href={`https://wa.me/919321060218`} target='_blank' rel='noreferrer'  className="contact-link">
            <i className="fa-brands fa-whatsapp"></i> Chat on WhatsApp: +91 9321060218
           
          </a>
          <a href="tel:+919321060218" target='_blank' rel='noreferrer'  className="contact-link">
            <i className="fas fa-phone"></i> Call Us: +91 9321060218
          </a>
        </div>
        
        {/* Footer Note */}
        <div className="footer-note">
          Made with <i className="fas fa-heart" style={{ color: 'red' }}></i> by Aamantran
        </div>
      </div>
    </footer>
  </>
  )
}

export default Footer