
import React,{useState} from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import {snackbarContext} from '../../helpers/Snakbar'
import Cropper from 'react-easy-crop'
import getCroppedImg from "../../helpers/cropImage";
import {dataURLtoFile} from '../../helpers/dataURLtoFile'
import BackDrop from '../../helpers/BackDrop';
import { useDispatch } from 'react-redux';
import { uploadAvatar } from '../../../redux/reducers/endSlice';
const EndCropper = ({handleCropper,setAvatar,avatar}) => {
    const setStateSnackbarContext=React.useContext(snackbarContext)
    const inputRef =React.useRef()
    const [image,setImage]=useState(null)
    const [crop,setCrop]=useState({x:0,y:0})
    const [zoom,setZoom]=useState(1)
    const [cropArea,setcropArea]=useState(null)
    const [showBackDrop,setShowBackDrop]=useState(false)
    const dispatch=useDispatch()
    const onSelectFile = (event) => {
      const allowedExtensions = ['.jpeg', '.jpg', '.png', '.webp', '.heif', '.heic'];
      const file = event.target.files[0];
    
      if (!file) {
          return setStateSnackbarContext(true, "Please select an image", "warning");
      }
    
      const fileExtension = file.name.split('.').pop().toLowerCase();
    
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
          event.target.value = "";
          return setStateSnackbarContext(true, "Invalid file type", "error");
      }
    
      if (file.size > 5000000) {
          event.target.value = "";
          return setStateSnackbarContext(true, "File size too big (less than 5MB)", "warning");
      }
    
      const reader = new FileReader();
      reader.readAsDataURL(file);
    
      reader.addEventListener('load', () => {
          setImage(reader.result);
      });
    
      event.target.value = "";
    };
    
        const onCropComplete=(croppedAreaPercentage,croppedAreaPixels)=>{
            setcropArea(croppedAreaPixels)
          }

          const handleScale = (e) => {
            const scale = parseFloat(e.target.value);
            setZoom(scale);
          };
          const triggerFilePopup=()=>inputRef.current.click()
          const onClear=()=>{
         
            setImage(null)
            if(!image)
            return setStateSnackbarContext(
              true,
              "Please select an image",
              "warning"
            )
          }

          const onUpload=async()=>{
            if(!image)
            return setStateSnackbarContext(
              true,
              "Please select an image",
              "warning"
            )
            const canvas=await getCroppedImg(image,cropArea)
            const canvasDataUrl=canvas.toDataURL("image/jpeg")
            const connvertedDataUrl=dataURLtoFile(canvasDataUrl,'cropped-image.jpeg')
            const formdata=new FormData()
             formdata.append("endPhoto",connvertedDataUrl)
            setShowBackDrop(true)
            dispatch(uploadAvatar({formdata,handleCropper,setAvatar,avatar,setShowBackDrop,setStateSnackbarContext}))
        }
 
          return (
            <>
    <div className='default-bg-conatiner'>
         <CancelIcon  className='cancel-icon' onClick={handleCropper} />
         <input type="file"  ref={inputRef} style={{display:"none"}} onChange={onSelectFile}  />
         <div className="container-cropper">
            {
                image?<>
                <div className="cropper">
                <Cropper image={image} crop={crop} zoom={zoom}  aspect={1} onCropChange={setCrop} onZoomChange={setZoom}  onCropComplete={onCropComplete}  />
            </div>  
            <div className="slider"><span>Zoom:</span><input type="range"  min={1}  max={3} step={0.1} value={zoom} onChange={handleScale} />  </div>
                </>:null
            }
         </div>

         <div className="container-button">
           
           <button className="btn btn-primary options-buttons"   style={{marginRight:"5px"}} onClick={triggerFilePopup}>CHOOSE</button>
           <button className="btn btn-secondary options-buttons"   style={{marginRight:"5px"}}  onClick={onUpload} >UPLOAD</button>
           <button className="btn btn-danger options-buttons" onClick={onClear}  >CLEAR</button>
    
      </div>
    </div>
     {showBackDrop && <BackDrop/>}
    </>
  )
}

export default EndCropper