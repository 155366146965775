import React,{ useEffect, useRef,useState} from 'react'
import './landing.css'
import Navbar from '../helpers/Navbar'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { NavLink } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import {TabTitle} from '../helpers/GeneralFunctions'
import Footer from '../helpers/Footer';
const Landing = () => {
  TabTitle("Aamantran Dashboard")
  const {hasEnded,userCode,appEndingDate}=useSelector((state)=>state.auth.user)
  const cardsContainerRef = useRef(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const calculateRemainingDays = () => {
    const endDate = new Date(appEndingDate); // Convert appEndingDate to Date object
    const now = new Date(); // Current date

    // Calculate the time difference
    const timeDifference = endDate - now;

    // Convert time difference to days
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Set the remaining days in state
    setRemainingDays(daysRemaining);
  };

  useEffect(()=>{
    calculateRemainingDays();
  },[])
  
  return (
    <>
      <Navbar />

      <div className={hasEnded?"landing-container  landing-container-ended":"landing-container"}>
        <div className="landing-div">
        {hasEnded? 
         <div className="landing-content">
            <h1>Congratulations 🎉🎉 </h1>
            <h3>Your Special Day, Elevated! With Vabilo, feel the magic,Keep using Vabilo for post-wedding links and future memories.</h3>
            <button  onClick={() => {cardsContainerRef.current.scrollIntoView({ behavior: "smooth" });}} >Start Designing  <ArrowForwardIcon className='landing-icon' /></button>
          </div>: 
          <div className="landing-content">
            <h1>Let’s Get Started?</h1>
            <h3>It's Your Special Day, and You Want to Make It Different! Worry Less; Aamantran Is Your Companion to Make You Feel Special.</h3>
            <button   onClick={() => {cardsContainerRef.current.scrollIntoView({ behavior: "smooth" });}}>Start Designing  <ArrowForwardIcon className='landing-icon' /></button>
          </div>}
         
          <div className="landing-image">
            <img src="Group1.png" alt="" />
            <img src="Group.png" alt="" />
            <img src="Group2.png" alt="" />
          </div>
        </div>
      </div>
      {hasEnded? <h1 className="landing-heading">Design Your Thanks Page</h1>:
       <h1 className="landing-heading">Design Your InvitaTion</h1>}
    {hasEnded?<div className="cards-container" ref={cardsContainerRef}>
    <div className="single-card">
 <img src="design.png" alt="" />
 <h3>Edit Thanks Page</h3>
 <p> Enter Your Prewedding Links,Photos Links etc</p>
 <NavLink to="/links" className="buttonended"> Click Here </NavLink>
</div>

    </div>:   <div className="cards-container" ref={cardsContainerRef}>

    <div className="single-card">
 <img src="step1.png" alt="" />
 <h3>Wedding Details</h3>
 <p> Enter Bride Name,Groom Name,Wedding Date,Wedding Hastag etc</p>
 <NavLink to="/details"> Customize Invite </NavLink>
</div>

<div className="single-card">
 <img src="step2.png" alt="" />
 <h3>Create Events</h3>
 <p>Add Event details to share with your guests, such as Reception, Haldi, Sangeet,etc</p>
 <NavLink to="/event/new">Add Event</NavLink>
</div>


<div className="single-card">
 <img src="step3.png" alt="" />
 <h3>End This Invitation</h3>
 <p>You can end this invitation to share your memorable photos with your guests
and family</p>
{remainingDays > 0 && (
            <h4 className="landed-countdown-heading">
              ( Your invite will end in {remainingDays} {remainingDays === 1 ? 'day' : 'days'} )
            </h4>
          )}
 <NavLink to="/endinvitation">End Invitation</NavLink>
</div>
</div>}

   <Footer  userCode={userCode}/>
    </>

  )
}

export default Landing