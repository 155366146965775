
import React, { useEffect, useState } from 'react'
import './link.css'
import DisplayLinks from './DisplayLinks'
import Navbar from '../../helpers/Navbar'
import SubNavigation from './SubNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { addLink, getAllLinks,clearError } from '../../../redux/reducers/endSlice'
import inputErrors from '../../helpers/validation'
import { snackbarContext } from '../../helpers/Snakbar'
import { clearUser } from '../../../redux/reducers/authSlice'
import { useNavigate } from 'react-router-dom'
import { TabTitle } from '../../helpers/GeneralFunctions'
import Loader from '../../helpers/Loader'
import Footer from '../../helpers/Footer'
const CreateLink = () => {
    TabTitle("Add Links")
    const navigate=useNavigate()
    const setStateSnackbarContext=React.useContext(snackbarContext)
    const {links}=useSelector((state)=>state.end.endProfile)
    const {loading}=useSelector((state)=>state.end)
    const {user} = useSelector(state => state.auth);
 
    const {error:backendError}=useSelector((state)=>state.end)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        linkTitle:"",
        url:""
    })
    const [isSubmitting, setSubmitting] = useState(false);
     const dispatch=useDispatch()
    useEffect(()=>{
     dispatch(getAllLinks())
    },[])
    const handleChange=(event)=>{
  
        setValues({
            ...values,
            [event.target.name]: event.target.value
          });    
    }

    const handleSubmit=(e)=>{
       e.preventDefault()
       const validationErrors=inputErrors(values)
       setErrors(validationErrors)
       setSubmitting(true)
    }

    const submitFunc=async()=>{
        setSubmitting(false)
      await  dispatch(addLink({values,setValues,setStateSnackbarContext}))
        dispatch(getAllLinks());
    }

    useEffect(()=>{
        if(isSubmitting){
            const noErrors = Object.keys(errors).length === 0;   
            if(noErrors){
              submitFunc()
            }
          }
          if (backendError === "session expired"  || backendError==="pls login to acess the resource") {
            setStateSnackbarContext(true, "session expired please login again", "error");
            dispatch(clearError());
            dispatch(clearUser())
            navigate("/login")
          } else if (backendError) {
            setStateSnackbarContext(true, backendError, "error");
            dispatch(clearError());
          }
    },[isSubmitting,errors,backendError])

    if(user && !user.hasEnded){
        navigate("/")
        }

    return (
        <>
       <Navbar/>
       <SubNavigation/>
        <div className="container">
       
            <div className="row d-flex justify-content-center">
          
                <div className="col-lg-8 col-md-11">
                    <div className="create-link-container px-4">
                        <h4 className="text-center my-4">CREATE A NEW LINK</h4>
                        <form className='d-flex flex-column align-items-center' onSubmit={handleSubmit}>
                            <div className={errors.linkTitle ? "link-field-error" : "link-field"}>
                                <input type="text"
                                    name='linkTitle'
                                    className='link-field-input'
                                      value={values.linkTitle}
                                   onChange={handleChange}
                                    placeholder=" "
                                />
                                <label>Title <span className='label-hide'>[ e.g. Pre wedding ]</span></label>
                                <div className='mt-3'>  {errors.linkTitle && (<small className="error-msg">{errors.linkTitle}</small>)} </div>

                            </div>

                            <div className={errors.url ? "url-field-error" : "url-field"}>
                                <input type="text"
                                    name='url'
                                    className='url-field-input'
                                      value={values.url}
                                     onChange={handleChange} 
                                    placeholder=" "
                                />
                                <label className='url-label'>Url <span className='label-hide'>[ https://youtube.com ]</span></label>
                                <div className='mt-3'>  {errors.url && (<small className="error-msg">{errors.url}</small>)} </div>
                            </div>
                            <button type="submit"  className="add-link-button">ADD</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {links && loading?<Loader/>: <DisplayLinks links={links}  />}
        <Footer userCode={user&&user.userCode}/>
        </>
       
    )
}

export default CreateLink